import { useRef } from 'react'
import { useOnClickOutside } from 'usehooks-ts'

type ITutorialModal = {
  close: () => void
}

/**
 * Modal window for tutorial how to use ACRIOS IDE
 */
const TutorialModal = (props: ITutorialModal) => {
  const ref = useRef(null)
  useOnClickOutside(ref, props.close)

  const video1 = 'rBRlvvW8IKU'
  const video2 = 'VfYBG65Jj5c'
  const video3 = '9b3Ed-jASyk'

  return (
    <div
      ref={ref}
      style={{
        backgroundColor: '#1e1e1e',
        width: '80%',
        height: '80%',
        color: '#ccc',
        padding: '1rem 2rem',
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'monospace',
      }}
    >
      

      <div
        style={{
          flexGrow: '1',
          overflow: 'auto',
          paddingRight: '1rem',
        }}
      >
        <div>
<p>
<h2>Welcome to the ACRIOS GUI</h2>
<hr></hr>
<h4>Graphical User Interface designed for:</h4>
<li>Editing and managing Lua scripts in your ACRIOS device</li>
<li>Testing your functionalities with Interactive Mode</li>
<li>Uploading a new firmware</li>
<li>Scanning for M-Bus devices</li>

 <section>This tutorial will guide you through the main features of ACRIOS GUI, helping you to effectively use this platform for the ACRIOS converter configuration.</section>

</p>
</div>
        <div style={{ padding: '1rem 0', display: 'flex' }}>
          <iframe
            style={{ margin: 'auto' }}
            title="YouTube Video"
            width="960"
            height="585"
            src={`https://www.youtube.com/embed/${video1}`}
            frameBorder="0"            
            allowFullScreen
          />
        </div>      
        <div>
<div> 
<br></br>
<br></br>
<h3>List of Items</h3>
<br></br>
<li>1. How to Connect the Device</li>
<ul><li>1.1 Step by Step Explanation</li></ul>
<li>2. The Top Right Buttons</li>
<ul><li>2.1 Connecting the Device</li>
  <ul>
    <li>2.1.1 Connect</li>
    <li>2.1.2 Config</li>
    <li>2.1.3 Disconnect</li>
  </ul>
 <li>2.2 Working with Lua Script</li> 
  <ul>
    <li>2.2.1 Read</li>
    <li>2.2.2 Validate</li>
    <li>2.2.3 Write</li>
    <li>2.2.4 Interactive Mode</li>
  </ul>
  <li>2.3 Other Buttons</li>
  <ul>
    <li>2.3.1 Update FW</li>
    <li>2.3.2 Device Info</li>
    <li>2.3.3 M-Bus Scanner</li>
  </ul>  
</ul>
<li>3. Top Left Menus</li>
<ul>
  <li>3.1 Top Left Sidebar</li>
  <ul>
    <li>3.1.1 Explorer</li>
  </ul>
  <li>3.2 Top Left Dropdown Menus</li>
  <ul>
    <li>3.2.1 File</li>
    <li>3.2.2 Edit</li>
    <li>3.2.3 Select</li>
    <li>3.2.4 View</li>
    <li>3.2.5 Run</li>
    <li>3.2.6 Help</li>
  </ul>
</ul>    
<br></br>
<br></br>
 <h2>1. How to Connect the Device</h2>
 <p>
 Note that you can find the following tutorial on our Wiki that explains GUI as well:
 <li><a href="https://wiki.acrios.com/tools/ACRIOS%20GUI/">Visual GUI Guide</a></li>
 </p>
 <h3>1.1 Step by Step Explanation</h3>
 <hr></hr>
 <p>
 In order to connect the device, you need to follow these steps.
</p>
 <li>Step 1: <b>Connect the device to the computer</b> via 3.5 mm jack to USB cable</li>
<li>Step 2: <b>Press Connect</b> and now the serial port will open
 <ul><li>
 Note, that if this is done for the first time, you need to also confirm the port in a pop up window
 </li></ul></li>
 <li>Step 3: <b>Press Config</b>, the button should appear once Connect has been pressed
 <ul><li>
 Note, that <b>NOT </b>pressing config also connects the device, but only for a serial log readout in a console - this way you may monitor the activity of the device, but can not interact with it
 </li></ul></li>
 <li>Step 4a: <b>Turn the device ON</b> or restart the device
 <ul><li>The device is turned ON by plugging in the power source</li></ul>
 <ul>
 <ul><li> If it has been plugged in already, you may still unplug the power source and short the device - e.g. with tweezers - and plug it in once again</li></ul>
 </ul></li>
 <li>Step 4b: Alternatively, if the device is running, you may also <b>force the restart by pressing the button repeatedly</b></li>
 <li>Step 5: <b>Skip the bootloader</b> by either holding the button for a while and releasing (NI-W-D) or simply pressing the button (other models such as L-M-D)</li>
 <ul>
 <li>You may also let the bootloader run its course, but note it will take quite some time (several minutes)</li>
</ul>
 <li>Step 6: Now the device should <b>connect</b> and you can:</li>
 <ul>
 <li>Work with Lua scripts - modify them, test them, write them onto the device</li>
</ul>
 <ul>
 <li>Interact with the device via Interactive mode</li>
</ul><ul>
 <li>Scan for M-Bus meters if using an M-Bus device</li>
</ul>
<div style={{ padding: '1rem 0', display: 'flex' }}>
          <iframe
            style={{ margin: 'auto' }}
            title="YouTube Video"
            width="960"
            height="585"
            src={`https://www.youtube.com/embed/${video2}`}
            frameBorder="0"            
            allowFullScreen
          />
        </div>      
        <div></div>
</div> 
<div><h2>2. The Top Right Buttons</h2>
<br></br>
 The menu on the top right contains <b>several buttons</b>. Note that while for the most part, <b>these are the only buttons you will need</b>. There are other advanced options, which is going to be covered later in the tutorial.
 <br></br>
 <br></br>
 <h3>2.1 Connecting the Device</h3> 
 <hr></hr>
 <p>
 <section>The following buttons are utilized, when the device is connected:</section>
</p>
 <h4>2.1.1 Connect</h4>
 <b>Opens a serial port</b> requested from the browser. This port is used to communicate with the ACRIOS device.
 <br></br><br></br>
 <h4>2.1.2 Config</h4>
 Is available only once Connect has been pressed. It is used to <b>connect the device to the GUI</b> so it <b>can be configured or tested</b> with help of Lua programming language.
 <br></br><br></br>
 <h4>2.1.3 Disconnect</h4>
 Closes a serial port and <b>disconnects the device</b>.
 <li>Note that it is a good practise to always press this button before manually disconnecting the device.</li>
 <br></br><br></br>
 <h3>2.2 Working with Lua Script</h3>
 <hr></hr>
 The main functionalities to <b>work with Lua scripts</b> are in the upper right part of the menu. Note that there are other advanced options to work with Lua scripts in the additional menus.
 <br></br><br></br>
 <h4>2.2.1 Read</h4>
 Reads the current script on the device and loads it into the editor (direct part of the GUI).
 <li>You can freely edit the script, but note, that you have to <b>write the script with the Write button</b> first to apply the changes on the device</li>
 <br></br><br></br>
 <h4>2.2.2 Validate</h4>
 Allows you to check, whether the script is valid and whether it can be compiled.
 <li>Note that if you attempt to write a script into the device, validation is also performed</li><li>This button mostly serves to validate work in progress scripts</li>
 <br></br><br></br>
 <h4>2.2.3 Write</h4>
 Allows you to write a script into the device.
 <li>The script is always validated, before it gets uploaded</li>
 <li>Also allows for these write options:</li>
 <ul><li>Write with debug symbols</li></ul>
 <ul><li>Write only compiled script</li></ul>
 <ul><li>Encrypt the script with a password</li></ul>
 <h4>2.2.4 Interactive Mode</h4>
 Allows you to enter one line snippets of code to test script functionalities and to interact directly with the device - e.g. scan for devices.
<ul>
 Try this code: temp=api.exec("_cpu_temp") print(temp.." degrees Celsius")
 <br></br>
 <i>Prints the temperature of the processor. </i>
</ul>

 <h3>2.3 Other Buttons</h3>
 <hr></hr>
 This is the explanation for the remaining buttons, that can be seen in the top right menu.
 <br></br><br></br>
 <h4>2.3.1 Update FW</h4>
 This functionality allows you to update firmware.
<ul>
 <li><b>!!!</b> Note that this is not the usual purpose of the GUI but we do not prevent our customers from accessing it. Just note you may experience difficulties if the firmware update is not executed properly. <b>!!!</b></li>
</ul>
 <ul>
 <li>Note that we do not publicly provide the firmware files, but in case you need them, you can contact us at <b>support@acrios.com</b></li>
</ul>
 <p>
 If you would like to update firmware, <b>follow firmly following steps</b>:
</p>
 <li>Step 1: <b>Unplug the device from its power source</b> and short it - e.g. with tweezers</li>
 <li>Step 2: <b>Unplug the 3.5 mm jack from the device</b> - but you need to <b>keep the USB cable ready in the computer</b> so you can perform the next step</li>
 <li> Step 3: Click on the <b>Connect button</b></li>
 <li> Step 4: Go to <b>Help</b> (upper left menu) and click on <b>Toggle Dev Features</b></li>
 <li> Step 5: Click on either <b>Update FW LoRaWAN button</b> or <b>Update FW NB-IoT button</b>
 <ul><li>
 Keep in mind, that LoRaWAN devices have L in its code name e.g. L-M-D and use FW files ending with *.fw2</li>
 </ul>
 <ul><li>
 Keep in mind, that NB-IoT devices have N in its code e.g. N-M-D and use FW files ending with *.fw</li>
 </ul></li>
 <li> Step 6: <b>Pick the correct firmware file</b> - the client will let you choose only the correct ones (see Step 5)</li>
 <li> Step 7: <b>Connect the device to the computer via 3.5 mm jack</b> to the computer</li>
 <li> Step 8: And finally, <b>plug the power source in the device</b> - the firmware should now be uploaded into the device</li>
 <ul>
 <li>Keep in mind, that the FW can be uploaded in such way indefinitely, so if you e.g. upload the wrong firmware file, you can simply repeat the entire process.</li>
</ul>
 <h4>2.3.2 Device Info</h4>
 Provides name of the device, its current state and information about the firmware version.
 <br></br><br></br>
 <h4>2.3.3 M-Bus Scanner</h4>
 Only possible when M-Bus compatible device is connected and configured.
 <li>Allows for primary and secondary scanning for connected devices, also you can read them out</li>
 <li>Requires the 2.14.2 firmware or later versions for it to work</li>
 <div style={{ padding: '1rem 0', display: 'flex' }}>
          <iframe
            style={{ margin: 'auto' }}
            title="YouTube Video"
            width="960"
            height="585"
            src={`https://www.youtube.com/embed/${video3}`}
            frameBorder="0"            
            allowFullScreen
          />
        </div>      
        <div></div>
 <br></br><br></br>
 <h2>3. Top Left Menus</h2>
 There are two menus available:
 <ul><li><b>Top left sidebar</b> - contains file explorer (files icon) and search function (search icon)</li></ul>
 <ul><li><b>Top left dropdown menus</b> - contain additional functions - divided into categories such as File, Edit, Selection...</li></ul>
 <h3>3.1 Top Left Sidebar</h3>
 <hr></hr>
 <h4>3.1.1 Explorer</h4>
 Allows you to browse all the files you have worked with within the session as well as with sample files - also provides some additional function such as saving etc.
<br></br>
<br></br>
 <b>OPEN EDITORS</b>
 <br></br>
 Contains all the currently opened Lua scripts.
 <br></br>
 <b>LUA FILES</b>
 <br></br>
 Contains all the files you have been working with as well newly created files and sample Lua repository - contains all the general use Lua scripts.
 <br></br>
 <b>Lua Sample Repository</b>
 <br></br>
 It contains all the general use up to date Lua scripts - ready to use. Especially useful if you simple want to access our pre-configured Lua script solutions.
 <br></br>
 <b>Device Readouts History</b>
 <br></br>
 Contains device readouts history - Lua files that were loaded by the Read button.
 <br></br>
 <b>Local Files</b>
 <br></br>
 Contains the files, that we uploaded via File &#8594; Import Local Files.
 <br></br>
 <b>Search</b>
 <br></br>
 Search function allowing you to search within the files.
 <br></br>
 <br></br>
 <h3>3.2 Top Left Dropdown Menus</h3>
 <hr></hr>
 <h4>3.2.1 File</h4>
 <section>
 Serves primarily for manipulation with files - opening, importing, creating etc.
</section>
<ul><li>New File - create a new file</li></ul>
 <ul><li>Open - open a file</li></ul>
 <ul><li>New File From Clipboard - insert a copied text (code) into a newly created Lua script</li></ul>
 <ul><li>Import Local Files(s) - upload a Lua script - appears in Local Files</li></ul>
 <ul><li>Download File - downloads the opened file</li></ul>
 <h4>3.2.2 Edit</h4>
 <section> Allows to trigger Ctrl+Z or Ctrl+Shift+Z.</section>
 <ul><li>Undo - triggers Ctrl+Z</li></ul>
 <ul><li>Redo - triggers Ctrl+Shift+Z</li></ul>
 <h4>3.2.3 Select</h4>
 <section>Allows to trigger Ctrl+A or Alt+Shift+↑.</section>
 <ul><li>Select All - triggers Ctrl+A</li></ul>
 <ul><li>Copy Line Up - triggers Alt+Shift+↑</li></ul>
 <h4>3.2.4 View</h4>
 <section>This menu controls what features of GUI are visible - e.g. you accidentally close a tab and here you can open it again.</section>
 <ul><li>Command Palette - opens a command palette, also can be opened via Ctrl+Shift+P</li></ul>
 <ul><li>Open View</li></ul>
 <ul><li>Appearance - Allows you to toggle visibility of certain GUI features</li></ul>
 <h4>3.2.5 Run</h4>
 <section> Allows you to trigger the Validate or Write buttons.</section>
 <ul><li>Validate - triggers validation, checks whether the script is functional</li></ul>
 <ul><li>Compile and Deploy - allows you to deploy the script into the device</li></ul>
 <h4>3.2.6 Help</h4>
 <section> Contains all the necessary resources and links to documentation including Wiki link, this tutorial, changelog as well as it allows <b>toggling Dev Features</b>.</section>
 <ul><li>ACRIOS Wiki - opens our Wiki which contains all our documentation</li></ul>
 <ul><li>Tutorial - opens this tutorial</li></ul>
 <ul><li>Firmware Changelog - opens the firmware changelog</li></ul>
 <ul><li>Toggle Dev Features - opens both options for firmware uploading - either NB-IoT or LoRaWAN device</li></ul>
 <ul><li>Toggle Serial Port Filters</li></ul>
 <ul><li>About - opens info about the current version of the GUI </li></ul>
 </div>

</div>
        
      </div>
          
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '2rem 0 0.5rem 0',
        }}
      >
        
        <p><section>You can find additional materials at <a href="https://wiki.acrios.com/">wiki.acrios.com</a>.</section>
        <section>You can contact our tech support at <i>support@acrios.com</i>.</section></p>
        <button
          style={{
            cursor: 'pointer',
            color: '#fff',
            backgroundColor: '#0e639c',
            padding: '0.4rem 1rem',
            border: '0',
          }}
          onClick={props.close}
        >
          Close
        </button>
      </div>
    </div>
  )
}

export default TutorialModal
